interface Channel {
    country: string,
    icmChannel: string
}

// Update list every time a new channel is rolled out
export const channelList: Channel[] = [
    {
        country: 'Brazil',
        icmChannel: 'rijkzwaan-brazil-Site/rest;loc=pt_BR;cur=BRL'
    },
    {
        country: 'Germany',
        icmChannel: 'rijkzwaan-germany-Site/rest;loc=de_DE;cur=EUR'
    },
    {
        country: 'Austria',
        icmChannel: 'rijkzwaan-austria-Site/rest;loc=de_AT;cur=EUR'
    },
    {
        country: 'Ukraine',
        icmChannel: 'rijkzwaan-ukraine-Site/rest;loc=uk_UA;cur=UAH'
    },
    {
        country: 'Poland',
        icmChannel: 'rijkzwaan-poland-Site/rest;loc=pl_PL;cur=PLN'
    },
    {
        country: 'Australia',
        icmChannel: 'rijkzwaan-australia-Site/rest;loc=en_AU;cur=AUD'
    },
    {
        country: 'United Kingdom',
        icmChannel: 'rijkzwaan-united_kingdom-Site/rest;loc=en_GB;cur=GBP'
    },
    {
        country: 'Scandinavia',
        icmChannel: 'rijkzwaan-scandinavia-Site/rest;loc=en_DK;cur=EUR'
    },
    {
        country: 'Belgium',
        icmChannel: 'rijkzwaan-belgium-Site/rest;loc=nl_BE;cur=EUR',
    },
    {
        country: 'Greece',
        icmChannel: 'rijkzwaan-greece-Site/rest;loc=el_GR;cur=EUR',
    },
    {
        country: 'Hungary',
        icmChannel: 'rijkzwaan-hungary-Site/rest;loc=hu_HU;cur=HUF',
    },
    {
        country: 'Italy',
        icmChannel: 'rijkzwaan-italy-Site/rest;loc=it_IT;cur=EUR',
    },
    {
        country: 'France',
        icmChannel: 'rijkzwaan-france-Site/rest;loc=fr_FR;cur=EUR',
    },
    {
        country: 'Portugal',
        icmChannel: 'rijkzwaan-portugal-Site/rest;loc=pt_PT;cur=EUR',
    },
    {
        country: 'Spain',
        icmChannel: 'rijkzwaan-spain-Site/rest;loc=es_ES;cur=EUR',
    },
    {
        country: 'Argentina',
        icmChannel: 'rijkzwaan-argentina-Site/rest;loc=es_AR;cur=ARS',
    },
    {
        country: 'Chile',
        icmChannel: 'rijkzwaan-chile-Site/rest;loc=es_CL;cur=CLP',
    },
    {
        country: 'Guatemala',
        icmChannel: 'rijkzwaan-guatemala-Site/rest;loc=es_GT;cur=GTQ',
    },
    {
        country: 'Mexico',
        icmChannel: 'rijkzwaan-mexico-Site/rest;loc=es_MX;cur=MXN',
    },
    {
        country: 'Russia',
        icmChannel: 'rijkzwaan-russia-Site/rest;loc=ru_RU;cur=RUB',
    },
    {
        country: 'Kazakhstan',
        icmChannel: 'rijkzwaan-kazakhstan-Site/rest;loc=kk_KZ;cur=KZT',
    },
    {
        country: 'Turkey',
        icmChannel: 'rijkzwaan-turkey-Site/rest;loc=tr_TR;cur=TRY',
    },
    {
        country: 'South Africa',
        icmChannel: 'rijkzwaan-south_africa-Site/rest;loc=en_ZA;cur=ZAR',
    },
    {
        country: 'India',
        icmChannel: 'rijkzwaan-india-Site/rest;loc=en_IN;cur=INR',
    },
    {
        country: 'USA',
        icmChannel: 'rijkzwaan-usa-Site/rest;loc=en_US;cur=USD',
    },
    {
        country: 'Morocco',
        icmChannel: 'rijkzwaan-morocco-Site/rest;loc=fr_MA;cur=MAD',
    },
    {
        country: 'China',
        icmChannel: 'rijkzwaan-china-Site/rest;loc=zh_CN;cur=CNY',
    },
    {
        country: 'Egypt',
        icmChannel: 'rijkzwaan-egypt-Site/rest;loc=ar_EG;cur=EGP',
    },
    {
        country: 'Vietnam',
        icmChannel: 'rijkzwaan-vietnam-Site/rest;loc=vi_VN;cur=VND',
    },
    {
        country: 'Africa',
        icmChannel: 'rijkzwaan-africa-Site/rest;loc=en_TZ;cur=TZS',
    },
    {
        country: 'Morocco',
        icmChannel: 'rijkzwaan-morocco-Site/rest;loc=fr_MA;cur=MAD',
    },
    {
        country: 'Netherlands',
        icmChannel: 'rijkzwaan-netherlands-Site/rest;loc=nl_NL;cur=EUR',
    },
    {
        country: 'Middle East North Africa',
        icmChannel: 'rijkzwaan-mena-Site/rest;loc=ar_JO;cur=JOD',
    },
    {
        country: 'Canada',
        icmChannel: 'rijkzwaan-canada-Site/rest;loc=en_CA;cur=CAD',
    },
    {
        country: 'South Korea',
        icmChannel: 'rijkzwaan-south_korea-Site/rest;loc=ko_KR;cur=KRW',
    },
    {
        country: 'Honduras',
        icmChannel: 'rijkzwaan-honduras-Site/rest;loc=es_HN;cur=HNL',
    },
    {
        country: 'Costa Rica',
        icmChannel: 'rijkzwaan-costa_rica-Site/rest;loc=es_CR;cur=CRC',
    },
    {
        country: 'Panama',
        icmChannel: 'rijkzwaan-panama-Site/rest;loc=es_PA;cur=PAB',
    } 
];