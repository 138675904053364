import { useState } from "react";
import { Button, Form, FormControl, Select, TextInput } from '@contentful/f36-components';

import { channelList } from "../utils/channel-list.ts";
import "./ProductSearch.css";

export default function ProductSearch(props) {
    const [limit, setLimit] = useState(6);
    const [term, setTerm] = useState('Celery');
    const [channel, setChannel] = useState('rijkzwaan-australia-Site/rest;loc=en_AU;cur=AUD');

    function submitForm() {
        if (props) {
            props.onSearch([limit, term, channel]);
        }
    }

    return (
        <Form onSubmit={submitForm} className="ProductSearch">
            <FormControl isRequired>
                <FormControl.Label>Channel</FormControl.Label>
                <Select
                    name="channel"
                    value={channel}
                    onChange={(e) => setChannel(e.target.value)}
                >
                    {channelList.map(channel => (
                        <Select.Option key={channel.country} value={channel.icmChannel}>{channel.country}</Select.Option>
                    ))}
                </Select>
                <FormControl.HelpText>Provide a channel to search for products</FormControl.HelpText>
            </FormControl>

            {!props.isCrop && (
                <FormControl isRequired>
                    <FormControl.Label>Limit</FormControl.Label>
                    <TextInput
                        value={limit}
                        type="number"
                        name="limit"
                        onChange={(e) => setLimit(e.target.value)}
                    />
                    <FormControl.HelpText>Provide a maximum amount of products to display</FormControl.HelpText>
                    {!limit && (
                        <FormControl.ValidationMessage>
                            Please, provide a limit amount
                        </FormControl.ValidationMessage>
                    )}
                </FormControl>
            )}

            {!props.isCrop && (
                <FormControl>
                    <FormControl.Label>Search Term</FormControl.Label>
                    <TextInput
                        value={term}
                        type="text"
                        name="term"
                        placeholder="Search"
                        onChange={(e) => setTerm(e.target.value)}
                    />
                    <FormControl.HelpText>Provide a term to search for specific products</FormControl.HelpText>
                    {!term && (
                        <FormControl.ValidationMessage>
                            Please, provide a search term
                        </FormControl.ValidationMessage>
                    )}
                </FormControl>
            )}
            <Button variant="primary" type="submit">
                Search products
            </Button>
        </Form>
    );
}
